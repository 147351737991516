import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    &.is-scrolled-to-top {
        .bottom {
            position: relative;
            box-shadow: initial;
            background: transparent;

            &:after {
                opacity: 0;
                transform: translateY(-100%);
            }

            .col-right {
                .gender-zone-buttons {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .bottom {
        position: relative;

        &.d-none {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: #262626;
            opacity: 1;
            transform: translateY(0);
            z-index: -1;
            transition: transform 100ms ease;
        }

        .layout-container {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .col-right {
            .gender-zone-buttons {
                padding: 0.3em;
                border-radius: 0.5em;
                white-space: nowrap;
                background-color: rgba(255, 255, 255, 0.1);

                .gender-button {
                    background-color: transparent;
                    text-decoration: none;
                    color: #FFFFFF;
                    padding: 0.3em 1em;
                    border-radius: 0.5em;
                    font-size: 14px;
                    transition: background-color 200ms ease;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.3);
                    }

                    &.active {
                        background: ${vars.backgroundButtonGradient};

                        &:hover {
                            background-color: #CFB799;
                        }
                    }
                }
            }
        }
    }
`;
