import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: absolute;
	top: 0;
	left: calc(200% + 2em);
	width: 95%;
	height: 100%;
	font-size: 17px;
	color: #fff;
	padding: 2em 0.5em 0 0.5em;
	border-top: 2px solid rgb(62, 62, 62);
	overflow: hidden;
	overflow-y: scroll;

	display: flex;
	flex-flow: column nowrap;

	.second-step {
		&__header {
			display: flex;
			place-items: center;
			gap: 2em;
			margin: 0 0 1.2em 0;

			.active-title {
				color: #ba9448;
			}
		}

		&__mobile {
			width: 34px;
			height: 34px;
			display: flex;
			place-content: center;
			place-items: center;
			border: 2px solid ${vars.colorTextPrimary};
			border-radius: 10px;
			padding: 4px;

			&--active {
				background-color: #ba9448;

				img {
					filter: brightness(0) invert(1);
				}
			}
		}
	}
`;
