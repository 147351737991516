import { FunctionComponent } from 'react';
import Link from 'next/link';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Props } from './types';

const PublicLayoutNavigationDesktopPagesLinksItem: FunctionComponent<Props> = ({ key, title, subTitle, className, href, visiblePin, boldedAttribute }) =>
    href ? (
        <Link
            key={`${key}${title}`}
            href={href}
        >
            <a
                className={className}
                {...(boldedAttribute ? { 'bolded-text': title } : {})}
            >
                {visiblePin && (
                    <FontAwesomeIcon
                        className="pin-icon"
                        icon={faMapMarkerAlt}
                        width="10px"
                        height="12px"
                    />
                )}
                {title}
                <p className="sub-title">{subTitle}</p>
            </a>
        </Link>
    ) : (
        <span
            key={`${key}${title}`}
            className={className}
            {...(boldedAttribute ? { 'bolded-text': title } : {})}
        >
            {visiblePin && (
                <FontAwesomeIcon
                    className="pin-icon"
                    icon={faMapMarkerAlt}
                    width="10px"
                    height="12px"
                />
            )}
            {title}
            <p className="sub-title">{subTitle}</p>
        </span>
    );

export default PublicLayoutNavigationDesktopPagesLinksItem;
