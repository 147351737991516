import { FunctionComponent, useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { getLocaleConfig } from 'utils/locale';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonIconPositions, ButtonIconTypes, ButtonStyles } from 'components/layout/Button';
import Logo from 'components/layout/Logo';
import InfoLineNavSection from 'components/modules/public/layout/Infoline';

import InfoBar from '../Desktop/InfoBar';

import DropDownMenu from './DropDownMenu';
import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutNavigationMobile: FunctionComponent<Props> = ({ state, isBannerVisible, bannerContent, bannerLink }) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const localeContext = useContext(LocaleContext);
    const router = useRouter();

    const lightLogo = getLocaleConfig()[localeContext.locale]?.defaultLogoLightUrl;
    const translations = localeContext?.translations?.navigation;

    const isLandingPageView = router.pathname === Routes.LandingPages;

    useEffect(() => {
        if (showMenu) {
            document.body.style.overflow = 'hidden';
            document.body.style.maxHeight = '100vh';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.maxHeight = '';
        };
    }, [showMenu]);

    useEffect(() => {
        setShowMenu(false);
    }, [router.asPath]);

    return (
        <StyledComponent className={classnames('public-layout-navigation-mobile', { 'topbar': isBannerVisible })}>
            {isBannerVisible && (
                <InfoBar
                    bannerContent={bannerContent}
                    bannerLink={bannerLink}
                />
            )}
            <div className="navigation-wrapper">
                <div className="col-left">
                    {isLandingPageView ? (
                        <Logo logo={lightLogo} />
                    ) : (
                        <a
                            className="logo-link"
                            href="/"
                        >
                            <Logo logo={lightLogo} />
                        </a>
                    )}
                </div>
                <div className="col-right">
                    <InfoLineNavSection
                        translations={translations}
                        isScrolledTop
                        isMobile
                    />
                    {!isLandingPageView && (
                        <>
                            <div className="profile">
                                <Link
                                    href={Routes.Panel}
                                    prefetch={false}
                                >
                                    <div className="profile-button">
                                        {state?.authToken && (<div className="is-logged-in" />)}
                                        <Button
                                            className={classnames(
                                                {
                                                    'profile-link': true,
                                                    'profile-link-logged-in': !!state?.authToken,
                                                }
                                            )}
                                            style={ButtonStyles.None}
                                            icon={{
                                                value: state?.authToken ? '/images/menu/profile-gold.svg' : '/images/menu/profile.svg',
                                                type: ButtonIconTypes.Image,
                                                position: ButtonIconPositions.After,
                                            }}
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div className="cart">
                                <Link
                                    href={Routes.PublicCart}
                                    prefetch={false}
                                >
                                    <div className="cart-button">
                                        {state?.cart?.items.length > 0 && (
                                            <div className="cart-counter">
                                                {state?.cart?.items?.length}
                                            </div>
                                        )}
                                        <Button
                                            className="cart-link"
                                            style={ButtonStyles.None}
                                            icon={{
                                                value: '/images/menu/cart.svg',
                                                type: ButtonIconTypes.Image,
                                                position: ButtonIconPositions.After,
                                            }}
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div
                                className="menu-button-container"
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <div
                                    className={classnames({
                                        'menu-button': true,
                                        'menu-button-active': showMenu,
                                    })}
                                />
                            </div></>
                    )}

                </div>
                {showMenu && (
                    <DropDownMenu
                        isBannerVisible={isBannerVisible}
                        translations={{
                            genderButtonsMobile: translations.genderButtonsMobile,
                            pages: translations.main,
                        }}
                    >
                        <InfoLineNavSection
                            translations={translations}
                            isScrolledTop
                            isMobile
                            mobileMenu
                        />
                    </DropDownMenu>
                )}
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobile;
