import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutNavigationMobileDropDownMenuFirstStep: FunctionComponent<Props> = ({ firstStepElement, className, setFirstStepElement, setSecondStepElement }) => {
    const router = useRouter();

    return (
        <StyledComponent className={classnames('public-layout-navigation-mobile-drop-down-menu-first-step', 'first-step', className)}>
            {firstStepElement?.page && (
                <div
                    className="first-step__header"
                >
                    {firstStepElement?.page.mobileIcon && (
                        <div className="first-step__mobile first-step__mobile--active">
                            <img
                                src={firstStepElement?.page.mobileIcon}
                                alt="icon"
                                width="auto"
                                height="26px"
                            />
                        </div>
                    )}
                    <span className="active-title">
                        {firstStepElement?.page.buttonProps.children}
                    </span>
                </div>
            )}
            <ul className="first-step__list">
                {Array.isArray(firstStepElement?.sections) && firstStepElement?.sections?.map?.((element, idx) => (
                    <li
                        key={element.title || idx}
                        className="first-step__item"
                        onClick={() => {
                            if (element.href) {
                                router.push(element.href);
                            } else {
                                setSecondStepElement(element);
                            }
                        }}
                    >
                        <div className="first-step__mobile">
                            <img
                                src={firstStepElement?.page.mobileIcon}
                                alt="icon"
                                width="auto"
                                height="26px"
                            />
                        </div>
                        <span>
                            {element.title}
                        </span>
                        {element.subsections && (
                            <img
                                className="arrow-icon"
                                src="/images/menu/arrow.svg"
                                alt="icon"
                                width="100px"
                                height="auto"
                            />
                        )}
                    </li>
                ))}
            </ul>
            <div
                className="step-back-button"
                onClick={() => setFirstStepElement(prevState => ({ ...prevState, toClose: true }))}
            >
                <img
                    className="arrow"
                    src="/images/menu/arrow.svg"
                    alt="icon"
                    width="100px"
                    height="auto"
                />
                <span>
                    Wróc
                </span>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobileDropDownMenuFirstStep;
