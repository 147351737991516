import styled from '@emotion/styled';

export default styled.div`
	display: flex;
	height: 70px;
	flex-direction: column;
	align-items: center;
	background-color: #262626;

	&.topbar {
		height: 100px;
	}

	.navigation-wrapper {
		width: 100%;
		height: 100%;
		padding: 1em 2em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.col-right {
			display: flex;
			align-items: center;
			gap: 1em;

			.profile {
				.profile-button {
					.profile-link {
						width: 34px;
						height: 26px;
					}

					.profile-link-logged-in {
						.button {
							.button-icon-after {
								border-color: #ba9349;
								padding: 0.4em;
							}
						}
					}
				}
			}

			.cart {
				.cart-button {
					position: relative;
                    margin-right: 0.3em;
                    
					.cart-link {
						width: 30px;
						height: 26px;
					}

					.cart-counter {
						position: absolute;
						color: white;
						background-color: #ba9449;
                        width: 16px;
						height: 16px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 22px;
						z-index: 2;
                        bottom: -2px;
						right: -5px;
                        font-size: 11px;
                        padding: 1px 0 0 0;
					}
				}
			}

			.menu-button-container {
				width: 32px;
				height: 32px;
				margin-left: 0.8em;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

				.menu-button {
					position: relative;
					height: 3px;
					width: 28px;
					background-color: white;
					border-radius: 3px;

					&:before,
					&:after {
						position: absolute;
						content: '';
						background-color: white;
						height: inherit;
						width: inherit;
						border-radius: inherit;
						transition: transform 200ms ease;
					}

					&:before {
						top: -8px;
					}

					&:after {
						bottom: -8px;
					}

					&-active {
						background-color: transparent;

						&:before {
							top: 0;
							transform: rotate(-45deg);
						}

						&:after {
							transform: rotate(45deg);
							bottom: 0;
						}
					}
				}
			}
		}
	}
`;
