import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;

	.info-line-nav {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: #ffffff;
		line-height: 120%;
		gap: 0.5em;
		font-size: 13px;

		.info-line-nav-content {
			display: flex;
			flex-flow: column wrap;
			align-items: flex-end;
			font-size: 15px;

			&-status {
				color: #ffffff;
				display: flex;
				justify-content: space-between;
				width: 100%;
				font-size: 13px;
				margin: 2px 0 0 0;

				&.active {
					color: #83ad47;
				}
			}
		}

		.dark-color {
			color: black;
		}

		&:hover {
			.info-line-nav-content-phone {
				position: relative;
				width: fit-content;

				&:before {
					content: '';
					position: absolute;
					height: 1px;
					width: 100%;
					left: 0;
					bottom: 0;
					background-color: #bebebe;
				}
			}
		}
	}

	.info-line-drop-down {
		position: absolute;
		background-color: white;
		padding: 1em;
		left: 50%;
		transform: translate(-50%, -20px);
		top: 3em;
		border-radius: 0.5em;
		box-shadow: 0 0.6em 1em rgba(72, 74, 82, 0.1);
		opacity: 0;
		transition: opacity 200ms ease, transform 200ms ease;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0.6em;
		z-index: 11;
		pointer-events: none;

		a {
			color: white;
			text-decoration: none;
			background-color: #ba9449;
			padding: 0.5em 2em;
			border-radius: 0.4em;
		}

		&.active {
			opacity: 1;
			transform: translate(-85%, 0);
			pointer-events: all;
		}
	}

	@media screen and (max-width: ${vars.tabletL}) {
		.info-line-nav {
			.info-line-nav-icon {
				img {
					width: auto;
					height: 26px;
				}
			}

			.info-line-nav-content {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;
				font-size: 17px;

				&-status {
					width: max-content;
					font-size: 17px;
				}
			}
		}
	}
`;
