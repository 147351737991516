import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';

import BlogsLinks from '../BlogsLinks';

import LinkItem from './PagesLinksItem';
import StyledComponent from './styles';
import { MenuState, Props, SectionClassNames } from './types';

const initialMenuState: MenuState = {
    visible: false,
    hover: {
        link: null,
        dropDown: null,
        subMenu: null,
    },
};

const PublicLayoutNavigationDesktopPagesLinks: FunctionComponent<Props> = ({ pages }) => {
    if (pages.length <= 0) return null;
    const [menuState, setMenuState] = useState<MenuState>(initialMenuState);

    const setVisible = (visible: boolean): void => setMenuState(prevState => ({ ...prevState, visible }));
    const onHover = (obj: Partial<MenuState['hover']>): void => setMenuState(prevState => ({
        ...prevState,
        hover: {
            ...prevState.hover,
            ...obj,
        },
    }));

    const renderSubSections = (subsections: any[], className: SectionClassNames): ReactNode => Array.isArray(subsections) && (
        <ul className={className}>
            {subsections?.map(subsection => renderSubSectionElement(subsection, className))}
        </ul>
    );

    const renderSubSectionElement = (subsection, className: SectionClassNames): ReactNode => {
        const canHovered = className === SectionClassNames.Subsection;
        const visiblePin = className === SectionClassNames.SubSubSection;

        return (
            <li
                key={subsection.key}
                className={classnames(`${className}__element`, {
                    'golden': Boolean(subsection.highlighted),
                    'hover': canHovered && menuState?.hover?.subMenu === subsection.key,
                })}
                onMouseEnter={() => canHovered && onHover({ subMenu: subsection.key })}
            >
                <div className={`${className}__element-content`}>
                    <LinkItem
                        key={subsection.key}
                        href={subsection.href}
                        visiblePin={visiblePin}
                        title={subsection.title}
                        subTitle={subsection?.subTitle}
                        className={`${className}__item`}
                    />
                </div>
                {/* Subsection can have sub-subsection */}
                {renderSubSections(subsection.subsections, SectionClassNames.SubSubSection)}
            </li>
        );
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!menuState.visible) timeout = setTimeout(() => setMenuState(initialMenuState), 600);

        return () => clearTimeout(timeout);
    }, [menuState.visible]);

    return (
        <StyledComponent className="public-layout-navigation-desktop-pages-links">
            <nav className="links-group">
                {pages.map((page, idx: number) => (
                    <div
                        key={`${page.key}-${idx}`}
                        className={classnames('link', {
                            'link--highlighted': page.buttonProps?.highlighted,
                            'has-sub-menu': page.subMenu,
                            'has-drop-down': page.dropDown,
                            'hover': page.key === menuState?.hover?.link,
                        })}
                        onMouseEnter={() => {
                            setVisible(true);
                            onHover({ link: page.key });
                        }}
                        onMouseLeave={() => setVisible(false)}
                    >
                        <LinkItem
                            key={`${page.key}-${idx}-${idx}`}
                            boldedAttribute={true}
                            className='link__anchor'
                            href={page?.buttonProps?.href}
                            title={page?.buttonProps?.children}
                        />
                        {page.subMenu && (
                            <>
                                <div className="link__chevron">
                                    <img
                                        src="/images/home/arrow-down-nav.svg"
                                        alt="icon"
                                        width="10px"
                                        height="10px"
                                    />
                                </div>
                                <div className={classnames('drop-down', {
                                    'drop-down__dynamic-content': page.subMenu.dynamicContent,
                                })}
                                >
                                    {page.subMenu.dynamicContent ? (
                                        <BlogsLinks
                                            firstVisibleCategory={page.subMenu.firstVisibleCategory}
                                            categories={page.subMenu.categories}
                                        />
                                    ) : page?.subMenu?.sections.map((section, idx: number) => (
                                        <LinkItem
                                            key={section.key || idx}
                                            className='drop-down__item'
                                            href={section.href}
                                            title={section.title}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                        {page.dropDown && (
                            <>
                                <div className="link__chevron">
                                    <img
                                        src="/images/home/arrow-down-nav.svg"
                                        alt="icon"
                                        width="10px"
                                        height="10px"
                                    />
                                </div>
                                <ul className="drop-down">
                                    <>
                                        {page.dropDown.sections.map((section, idx) => (
                                            <li
                                                className={classnames('drop-down__list', {
                                                    'hover': menuState?.hover?.dropDown === section.key,
                                                })}
                                                key={section.key || idx}
                                                onMouseEnter={() => onHover({ dropDown: section.key, subMenu: null })}
                                            >
                                                <LinkItem
                                                    key={section.key || idx}
                                                    className='drop-down__item'
                                                    href={section.href}
                                                    title={section.title}
                                                />
                                                <img
                                                    src="/images/home/arrow-right-nav.svg"
                                                    alt="icon"
                                                    width="10px"
                                                    height="10px"
                                                />
                                                {renderSubSections(section?.subsections, SectionClassNames.Subsection)}
                                            </li>
                                        ))}
                                    </>
                                </ul>
                            </>
                        )}
                    </div>
                ))}
            </nav>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationDesktopPagesLinks;
