import { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Gender, Genders } from 'models/Product';

import { withVariables } from 'utils/string';
import { getProvider } from 'services/Cookies';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import StyledComponent from './styles';
import { FirstStepElement, Props, SecondStepElement } from './types';

const PublicLayoutNavigationMobileDropDownMenu: FunctionComponent<Props> = ({ isBannerVisible, translations, children }) => {
    const [activeGender, setActiveGender] = useState<Genders>(null);
    const [firstStepElement, setFirstStepElement] = useState<FirstStepElement>(null);
    const [secondStepElement, setSecondStepElement] = useState<SecondStepElement>(null);

    const router = useRouter();

    const firstStepElementsNotEmpty = Boolean(Array.isArray(firstStepElement?.sections) && firstStepElement?.sections?.length > 0);
    const firstTranslate = firstStepElement?.toClose !== true && firstStepElementsNotEmpty;
    const secondTranslate = secondStepElement?.toClose !== true && Boolean(secondStepElement?.subsections?.length > 0);

    useEffect(() => {
        const usedGender = getProvider().get(Gender.gender);
        setActiveGender(usedGender as Genders);
    }, []);

    useEffect(() => {
        if (router.pathname === Routes.PublicWemanZone) setActiveGender(Genders.Female);
        if (router.pathname === Routes.PublicMenZone) setActiveGender(Genders.Male);
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (firstStepElement?.toClose) timeout = setTimeout(() => setFirstStepElement(null), 800);
        return () => clearTimeout(timeout);
    }, [firstStepElement]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (secondStepElement?.toClose) timeout = setTimeout(() => setSecondStepElement(null), 800);
        return () => clearTimeout(timeout);
    }, [secondStepElement]);

    return (
        <StyledComponent className={classnames('public-layout-navigation-mobile-drop-down-menu', { 'topbar': isBannerVisible })}>
            <div className={classnames('drop-down-wrapper', {
                'first-step-translate': firstTranslate,
                'second-step-translate': secondTranslate,
            })}
            >
                <div className="gender-zone-buttons">
                    {translations?.genderButtonsMobile?.buttonGroupProps?.buttons && (
                        translations?.genderButtonsMobile?.buttonGroupProps?.buttons.map((redirectButton) => (
                            <Link
                                key={redirectButton.children}
                                href={redirectButton.href}
                                prefetch={false}
                            >
                                <a
                                    className={classnames({
                                        'gender-button': true,
                                        'active': activeGender === redirectButton.key,
                                    }, redirectButton.key)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (redirectButton.key === Genders.Male) {
                                            getProvider().set(Gender.gender, Genders.Male);
                                            router.push(withVariables(Routes.PublicMenZone));
                                        }
                                        if (redirectButton.key === Genders.Female) {
                                            getProvider().set(Gender.gender, Genders.Female);
                                            router.push(withVariables(Routes.PublicWemanZone));
                                        }
                                    }}
                                >
                                    {redirectButton.children}
                                </a>
                            </Link>
                        ))
                    )}
                </div>
                <div className="pages">
                    {translations?.pages?.elements?.map((page) => (
                        <div
                            key={page.key}
                            className={classnames(
                                'pages-link',
                                {
                                    'highlighted': page.buttonProps?.highlighted,
                                },
                            )}
                        >
                            {page?.buttonProps?.href
                                ? (
                                    <Link
                                        href={page.buttonProps.href}
                                        prefetch={false}
                                    >
                                        <a
                                            className="pages-link-anchor"
                                        >
                                            {page.mobileIcon && (
                                                <div className="mobile-icon">
                                                    <img
                                                        src={page.mobileIcon}
                                                        alt="icon"
                                                        width="auto"
                                                        height="26px"
                                                    />
                                                </div>
                                            )}
                                            {page.buttonProps.children}
                                            {page.subMenu && page.buttonProps.href !== Routes.PublicBlog && (
                                                <img
                                                    className="arrow-icon"
                                                    src="/images/menu/arrow.svg"
                                                    alt="icon"
                                                    width="100px"
                                                    height="auto"
                                                />
                                            )}
                                        </a>
                                    </Link>
                                )
                                : (
                                    <div
                                        className="pages-link-anchor"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFirstStepElement({
                                                page,
                                                sections: page?.subMenu?.sections || page?.dropDown?.sections,
                                            });
                                        }}
                                    >
                                        {page.mobileIcon && (
                                            <div className="mobile-icon">
                                                <img
                                                    src={page.mobileIcon}
                                                    alt="icon"
                                                    width="auto"
                                                    height="26px"
                                                />
                                            </div>
                                        )}
                                        {page.buttonProps.children}
                                        {(page.subMenu || page.dropDown) && (
                                            <img
                                                className="arrow-icon"
                                                src="/images/menu/arrow.svg"
                                                alt="icon"
                                                width="100px"
                                                height="auto"
                                            />
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    ))}
                    <a
                        href="/strefa-kobiet/konsultacja-online/"
                        className="offer-button"
                        rel="noopener"
                    >
                        <img
                            src="/theme/images/globe.svg"
                            aria-hidden="true"
                            alt="globe"
                            className="icon"
                            width={20}
                            height={20}
                        />
                        <p className="offer-button-text">
                        Konsultacja online z kosmetologiem
                        </p>
                    </a>
                </div>
                <div className="info-line">
                    {children}
                </div>
                <FirstStep
                    className={classnames({
                        'active': Array.isArray(firstStepElement?.sections) && firstStepElement?.sections?.length > 0,
                    })}
                    firstStepElement={firstStepElement}
                    setFirstStepElement={setFirstStepElement}
                    setSecondStepElement={setSecondStepElement}
                />
                <SecondStep
                    firstStepElement={firstStepElement}
                    secondStepElement={secondStepElement}
                    setSecondStepElement={setSecondStepElement}
                />
            </div>
            <div className="info-line">
                {children}
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobileDropDownMenu;
